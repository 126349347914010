import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { getHTML } from "../components/utils"

const PageTemplate = ({ intl, data }) => {
  const pageId = data.current.fields.page.pageId
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} />
      <Hero
        label={intl.formatMessage({ id: `static.${pageId}.label` })}
      >
        <h2 className="hero-headline">
          {intl.formatMessage({ id: `static.${pageId}.hed` })}
        </h2>
        <h3 style={{ fontSize: '2.1rem' }}>
          {intl.formatMessage({ id: `static.${pageId}.dek` })}
        </h3>
      </Hero>
      <div
        className="static-body"
        dangerouslySetInnerHTML={{
          __html: getHTML(data),
        }}
      />
    </Layout>
  )
}

export default injectIntl(PageTemplate)

export const pageQuery = graphql`
  query($pageId: String!, $language: String!) {
    current: markdownRemark(
      fields: { page: { pageId: { eq: $pageId }, lang: { eq: $language } } }
    ) {
      html
      fields {
        page {
          path
          lang
          pageId
        }
      }
    }
    default: markdownRemark(
      fields: { page: { pageId: { eq: $pageId }, lang: { eq: "en" } } }
    ) {
      html
      fields {
        page {
          path
          lang
          pageId
        }
      }
    }
  }
`
